import { env } from '@/env'
import { authOptions } from '@/lib/auth-options'
import axios from 'axios'
import { Session, getServerSession } from 'next-auth'
import { getSession } from 'next-auth/react'

const api = axios.create({
  baseURL: env.NEXT_PUBLIC_API_URL,
})

let session: Session | null

const getApi = async (isSSR?: boolean) => {
  if (!session) {
    if (!isSSR) {
      session = await getSession()
    } else {
      session = await getServerSession(authOptions)
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const $session = session as any

  if ($session?.user?.accessToken) {
    api.defaults.headers.common.Authorization = `Bearer ${$session?.user?.accessToken}`
  }

  return api
}

const forgeApi = api

export { getApi, forgeApi }
