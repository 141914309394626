import { API_VERSION } from '@/config'
import { SignInResponse } from '@/contexts/auth-context'
import { getApi } from '@/services/api'
import { NextAuthOptions } from 'next-auth'
import CredentialsProvider from 'next-auth/providers/credentials'

export const authOptions: NextAuthOptions = {
  providers: [
    CredentialsProvider({
      credentials: {
        email: {
          label: 'Email',
          type: 'email',
        },
        password: {
          label: 'Password',
          type: 'password',
        },
      },
      async authorize(credentials) {
        if (!credentials || !credentials.email || !credentials.password) {
          throw new Error('Unauthorized.')
        }

        const { email, password } = credentials

        try {
          const response = await (
            await getApi()
          ).post<SignInResponse>(`${API_VERSION}/login`, {
            email,
            password,
          })

          const logged = await (
            await getApi()
          ).get(`${API_VERSION}/users/logged/${email}`)

          if (logged.data === 1) {
            if (credentials && credentials.email) {
              await (
                await getApi()
              ).post(`${API_VERSION}/logout`, {
                email: credentials.email,
              })
            }

            throw new Error('Unauthorized.')
          }

          const {
            token,
            user: { id, ...user },
          } = response.data

          ;(credentials as { [key: string]: string }).accessToken = token
          ;(await getApi()).defaults.headers.common.Authorization =
            `Bearer ${token}`

          return {
            id: String(id),
            email: user.email,
            name: user.name,
            image: user.avatar,
          }
        } catch (error) {
          throw new Error('Unauthorized.')
        }
      },
    }),
  ],
  session: {
    strategy: 'jwt',
    maxAge: 2 * 60 * 60, // 2h
  },
  jwt: {
    maxAge: 2 * 60 * 60, // 2h
  },
  callbacks: {
    async signIn({ account, credentials, user }) {
      if (credentials && user) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(user as any).accessToken = credentials.accessToken
      }
      if (account?.provider === 'credentials') {
        return true
      }

      return false
    },
    async jwt({ token, user }) {
      return {
        ...token,
        ...user,
      }
    },

    async session({ session, token }) {
      session.user = token

      return session
    },
  },
  pages: {
    signIn: '/auth',
  },
}
