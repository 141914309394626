import { API_VERSION } from '../../../config'
import { getApi } from '../../api'

export type Unity = {
  id: string
  unity: string
  createdAt: Date
  updatedAt: Date
}

type GetAllUnitiesParams = {
  ssr: boolean
}

export const getAllUnities = async (
  params?: GetAllUnitiesParams,
): Promise<Unity[]> => {
  const { ssr } = params || {}

  const response = await (
    await getApi(ssr)
  ).get<Unity[]>(`${API_VERSION}/users/unities/list-all`)

  return response.data
}

type CreateUnityData = Omit<Unity, 'id' | 'createdAt' | 'updatedAt'>

export const createUnity = async (data: CreateUnityData): Promise<Unity> => {
  const response = await (
    await getApi()
  ).post<Unity>(`${API_VERSION}/admins/users/unities/create`, data)

  return response.data
}

type UpdateUnityData = Partial<
  Omit<Unity, 'id' | 'createdAt' | 'updatedAt'>
> & {
  unityId: number
}

export const updateUnity = async (data: UpdateUnityData): Promise<Unity> => {
  const response = await (
    await getApi()
  ).post<Unity>(`${API_VERSION}/admins/users/unities/update`, data)

  return response.data
}
