import { getApi } from '@/services/api'

export async function dataUrlToFile(
  dataUrl: string,
  name: string,
  axios = false,
) {
  let blob: Blob

  if (axios) {
    const response = await (
      await getApi()
    ).get(`${dataUrl}`, { responseType: 'blob' })

    blob = response.data
  } else {
    const res = await fetch(dataUrl)

    blob = await res.blob()
  }

  return new File([blob], name, { type: 'image/png' })
}
