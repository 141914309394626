import { API_VERSION } from '../../../config'
import { getApi } from '../../api'
import { Unity } from '../unities'

type ChangePassData = {
  password: string
}
export type User = {
  id: number

  email: string | null
  name: string | null
  surname: string | null

  avatar: string | null
  avatar_url: string | null
  image: string | null

  canDownload: boolean | null
  connected: boolean | null
  enabled: boolean | null
  accessToken: string

  resetPasswordExpires: Date | null
  resetPasswordToken: string | null

  role: string | null
  lang: string

  unity_notification: boolean
  email_notification: boolean
  system_notification: boolean

  unity?: Unity['unity']
  Unity?: Unity

  createdAt: Date
  updatedAt: Date
}
export type GetUsersResponse = {
  users: User[]
  users_total_count: number
  users_count: number
}

type GetUsersFilterParams = Partial<{
  name: string
  email: string
  unity: string
  role: string
  canDownload: boolean
  enabled: boolean
  lang: string
}>

type GetUsersParams = {
  offset?: number
  limit?: number
  mode?: 'search' | 'list'
} & GetUsersFilterParams

type CreateUserData = {
  name: string
  email: string
  password: string
  role: string
  lang: string
  unity: string
}

export type UpdateUserData = {
  userId: number
} & Partial<{
  name: string
  email: string
  password: string
  canDownload: boolean
  enabled: boolean
  role: string
  lang: string
  unity: string
}>

type ForgotPassData = {
  email: string
}
type UpdatePassViaEmailData = {
  password: string
  passwordConfirm: string
  resetPasswordToken: string
}

export const changePass = async (data: ChangePassData): Promise<void> => {
  await (await getApi()).put(`${API_VERSION}/user/changePassword`, data)
}

export const forgotPass = async (data: ForgotPassData): Promise<void> => {
  await (await getApi()).post(`${API_VERSION}/users/forgotPass`, data)
}

export const updateUser = async (data: UpdateUserData): Promise<User> => {
  const response = await (
    await getApi()
  ).put<User>(`${API_VERSION}/admins/users/update`, data)

  return response.data
}

export const deleteUser = async (userId: number): Promise<void> => {
  await (await getApi()).delete<void>(`${API_VERSION}/user/${userId}`)
}

export const showUser = async (): Promise<User> => {
  const response = await (await getApi()).get<User>(`${API_VERSION}/profile`)

  return response.data
}

export const createUser = async (data: CreateUserData): Promise<User> => {
  const response = await (
    await getApi()
  ).post<User>(`${API_VERSION}/admins/users/create`, data)

  return response.data
}

export const searchUsers = async (
  params: GetUsersFilterParams,
): Promise<GetUsersResponse> => {
  const response = await (
    await getApi()
  ).get<GetUsersResponse>(`${API_VERSION}/users`, {
    params: {
      mode: 'search',
      ...params,
    },
  })

  return response.data
}

export const searchUsersByName = async (name: string): Promise<User[]> => {
  const response = await (
    await getApi()
  ).get(`${API_VERSION}/users/search?name=${name}`)

  return response.data
}

export const getUsers = async (
  params?: GetUsersParams,
): Promise<GetUsersResponse> => {
  const { offset, limit, mode, ...searchUsersParams } = params || {}

  if (mode === 'search') {
    return searchUsers(searchUsersParams)
  }

  const response = await (
    await getApi()
  ).get<GetUsersResponse>(`${API_VERSION}/users`, {
    params: {
      offset,
      limit,
    },
  })

  return response.data
}

export const getUsersCount = async (
  params: GetUsersFilterParams,
): Promise<GetUsersResponse> => {
  const response = await (
    await getApi()
  ).get<GetUsersResponse>(`${API_VERSION}/users`, {
    params: {
      mode: 'count',
      ...params,
    },
  })

  return response.data
}
export const updatePassViaEmail = async (
  data: UpdatePassViaEmailData,
): Promise<GetUsersResponse> => {
  const response = await (
    await getApi()
  ).put<GetUsersResponse>(`${API_VERSION}/users/updatePassViaEmail`, data)

  return response.data
}

export const updateUserAvatar = async (data: FormData): Promise<User> => {
  const response = await (
    await getApi()
  ).patch<User>(`${API_VERSION}/profile/avatar`, data)

  return response.data
}
