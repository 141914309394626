import { Option } from '@/components/multi-select'
import { dataUrlToFile } from '@/lib/data-url-to-file'
import { PartData } from '@/modules/app/parts/components/part'
import { optionsSchema } from '@/modules/app/parts/components/part/data/schema'
import { z } from 'zod'
import { API_VERSION } from '../../../config'
import { getApi } from '../../api'
import { Commentary, getCommentaries } from '../commentaries'
import { CostCalculatorValuesPerMaterial, File, getFiles } from '../files'
import { Label, getLabels } from '../labels'
import { User } from '../users'

type Model = {
  id: number
  type: 'model3d' | 'machining' | 'AMM' | null
  id_user: number
  enable: true
  createdAt: string
  updatedAt: string
  thumbnail_url: null
  private: boolean | null
  files: File[]
  revision_models: {
    id: number
    provider_code: string | null
    name: string
    email: string | null
    phone: string | null
    status: 'development' | 'test' | 'validated'
    sap_code: string | null
    saving_cost: null
    leadtime_reduction: null
    vault_code: string | null
    oem_code: string | null
    id_model: number
    id_user: number
    description: string | null
    observation: string | null
    type: 'model3d' | 'machining' | 'AMM' | null
    createdAt: string
    updatedAt: string
  }[]
}
export type Revision = {
  id: number | undefined
  user?: User | null
  revision: number
  createdAt?: string
}

export type Part = {
  id: number
  _id: 'model' | 'project'
  type: 'model3d' | 'machining' | 'AMM' | null
  user?: User
  commentaries?: Commentary[]
  thumbnail_url?: string | null
  enable?: boolean
  id_user: number
  private?: boolean | null
  favorited?: boolean
  files?: File[]
  provider_code?: string | null
  materials?: {
    id: number
    type: 'primary' | 'secondary'
  }[]
  phone?: string | null
  tools?: {
    id: number
  }[]
  revisionModels?: Revision[]
  labels: {
    equipments: Option[]
    nozzle_size: Option[]
    printers: Option[]
    labels: Option[]
    lines: Option[]
  }
  revisions?: Revision[]
  project_models?: { model: Model }[]
  models_under_development_percentage?: number
  models_under_test_percentage?: number
  models_validated_percentage?: number
  original_cost?: number | null
  saving_cost?: number | null
  original_leadtime?: number | null
  leadtime_reduction?: number | null
  name: string | null
  email?: string | null
  description?: string | null
  observation?: string | null
  sap_code?: string | null
  status?: string | null
  oem_code?: string | null
  vault_code?: string | null
  updatedAt?: string | null
  createdAt?: string | null
}

type SearchPartsParams = {
  id_user?: number
  partId?: number
  offset?: number
  limit?: number
  enabled?: 'yes' | 'no' | 'none'
  name?: string
  count?: string
  status?: string
  category?: string
  private?: string
  sort?: string
  sap_code?: string
  oem_code?: string
  vault_code?: string
  label?: string
  labels?: string[]
  unity?: string
}

type GetPartsParams = Partial<{
  offset: number
  limit: number
  mode: 'search' | 'list' | 'count'
}> &
  SearchPartsParams

export type GetPartsResponse = Part[]

type FetchPartData = {
  model?: {
    id_model: number
    enabled?: boolean
    type?: string
    id_revision_model?: number
  }
  project?: {
    id_revision?: number
    revision?: number
    id_project: number
    enabled?: boolean
  }
}

type GetPartLabelsParams = {
  id_project?: number
  id_revision_model?: number
}

const getPartLabels = async ({
  id_project: projectId,
  id_revision_model: revisionModelId,
}: GetPartLabelsParams) => {
  const defaultLabels: Part['labels'] = {
    equipments: [],
    nozzle_size: [],
    printers: [],
    labels: [],
    lines: [],
  }
  const labelParams = {
    id_project: projectId,
    id_revision_model: revisionModelId,
  }

  let labels = defaultLabels

  try {
    if (!labelParams.id_project && !labelParams.id_revision_model) {
      throw new Error()
    }

    const mapLabels = (labels: Label[]): Option[] => {
      return labels
        .filter(({ name }) => name)
        .map((label) => ({
          label: label.name!,
          value: label.name!.toLowerCase(),
        }))
    }
    labels.equipments = mapLabels(
      await getLabels({
        ...labelParams,
        category: 'equipment',
      }),
    )
    labels.labels = mapLabels(
      await getLabels({
        ...labelParams,
        category: 'generic',
      }),
    )
    labels.lines = mapLabels(
      await getLabels({
        ...labelParams,
        category: 'line',
      }),
    )

    if (revisionModelId) {
      labels.nozzle_size = mapLabels(
        await getLabels({
          ...labelParams,
          category: 'nozzle-size',
        }),
      )

      labels.printers = mapLabels(
        await getLabels({
          ...labelParams,
          category: 'printer',
        }),
      )
    }
  } catch {
    labels = defaultLabels
  }

  return labels
}
export const fetchPart = async ({
  model,
  project,
}: FetchPartData): Promise<Part> => {
  let commentaries: Commentary[] = []

  try {
    if (model?.id_model || project?.id_project) {
      commentaries = await getCommentaries({
        id_model: model?.id_model,
        id_project: project?.id_project,
      })
    }
  } catch {
    commentaries = []
  }

  let response: Part | null = null

  if (model) {
    response = (
      await (
        await getApi()
      ).get<Part>(`${API_VERSION}/models/show`, {
        params: model,
      })
    ).data
  }

  let lastRevision: Revision | null = null

  if (response && response.revisionModels) {
    lastRevision =
      response.revisionModels[response.revisionModels.length - 1] || null
  }
  const labels = await getPartLabels({
    id_revision_model: model?.id_revision_model || lastRevision?.id,
    id_project: project?.id_project,
  })

  if (model && response) {
    return {
      ...response,
      _id: 'model',
      commentaries,
      labels,
    }
  }
  if (project) {
    const response = await (
      await getApi()
    ).get(`${API_VERSION}/projects/show`, {
      params: project,
    })

    let files: File[] = []

    try {
      files = await getFiles({
        projectId: project.id_project,
        revisionProjectId: project.id_revision ? project.revision : undefined,
      })
    } catch {
      files = []
    }

    return {
      _id: 'project',
      commentaries,
      files,
      labels,
      ...response.data,
    }
  }
  throw new Error('Required model or project')
}

type CreatePartData = {
  model?: PartData['model'] & Omit<PartData, 'project'>
  project?: PartData['project'] & Omit<PartData, 'model'>
}

type EditPartData = {
  model?: Partial<CreatePartData['model']> & {
    modelId: number
    parentProjectId: number
  }
  project?: Partial<CreatePartData['project']> & { projectId: number }
}

type LabelFields = 'labels' | 'lines' | 'equipments' | 'printers' | 'nozzles'
type ParseLabelsOptions = {
  include: ['deletable']
}
export const parseLabels = (
  {
    equipments = [],
    labels = [],
    lines = [],
    nozzles = [],
    printers = [],
  }: Partial<Record<LabelFields, z.infer<typeof optionsSchema>>>,
  options?: ParseLabelsOptions,
) => {
  const allLabels = [
    [labels, 'generic'],
    [lines, 'line'],
    [equipments, 'equipment'],
    [printers, 'printer'],
    [nozzles, 'nozzle-size'],
  ] as [z.infer<typeof optionsSchema>, string][]

  const mappedLabels = allLabels.flatMap(([labels, category]) => {
    if (!options || !options.include.includes('deletable')) {
      labels = labels.filter((l) => !l.deletable)
    }

    return labels.map(({ label, deletable }) => {
      return {
        category,
        name: label,
        ...(options?.include.includes('deletable') && {
          deletable,
        }),
      }
    })
  })

  return mappedLabels
}
const parseFiles = (files: PartData['files']) => {
  return files
    .map((file, index) => {
      const fileType = file.name?.split('.').pop()?.toLowerCase()

      if (fileType === 'gcode') {
        return {
          index,
          fileId: file.id,
          partsQttd: file.partsQttd || 0,
        }
      }

      return null!
    })
    .filter((f) => f)
}

export const editPart = async ({
  model: modelData,
  project: projectData,
}: EditPartData): Promise<Part> => {
  if (projectData) {
    const {
      thumbnail: $thumbnail,
      files: allFiles = [],
      models = [],
      equipments = [],
      labels = [],
      lines = [],
      projectId,
      ...project
    } = projectData
    const formData = new FormData()

    const files = allFiles.filter((f) => !f.deletable)
    const newModels = models.filter((m) => !m.model?.id)

    newModels.forEach(({ model }) => {
      model?.files.forEach(async (file) => {
        const fileBlob = file.file

        if (fileBlob) formData.append('file', fileBlob)
      })
    })

    formData.append(
      'data',
      JSON.stringify({
        id_project: projectId,
        models: newModels.map(({ equipments, lines, labels, model, ...m }) => ({
          ...m,
          ...model,
          labels: parseLabels({
            printers: model?.printers,
            lines,
            labels,
            equipments,
            nozzles: model?.nozzles,
          }),
          files: model?.files
            .filter((f) => f.file)
            .map((f) => ({
              size: f.file!.size,
              name: f.file!.name,
              file: f.file,
            })),

          ...(model && {
            _model_files: parseFiles(model.files),
          }),
        })),
        labels: parseLabels(
          {
            equipments,
            labels,
            lines,
          },
          {
            include: ['deletable'],
          },
        ),
        _project_files: parseFiles(files.filter((f) => f._id)),
        _update_project_files: parseFiles(files.filter((f) => f.id)),
        ...project,
      }),
    )

    files?.forEach(({ file }) => {
      if (file) formData.append('project_file', file)
    })

    const api = await getApi()
    const thumbnail =
      $thumbnail || models.findLast((m) => m.thumbnail)?.thumbnail

    if (thumbnail) {
      const formDataThumb = new FormData()

      formDataThumb.append(
        'thumbnail',
        await dataUrlToFile(thumbnail, 'thumbnail.png'),
      )

      await api.put(
        `${API_VERSION}/project/${projectId}/thumbnail`,
        formDataThumb,
      )
    }
    const response = await api.put(`${API_VERSION}/project`, formData)

    const deletableFiles = allFiles.filter((f) => f.deletable && f.id)
    const deletableModels = models.filter((m) => m.deletable && m.model?.id)

    await Promise.all(
      deletableFiles.map((f) =>
        api.delete(`${API_VERSION}/files/disable?id_file=${f.id}`),
      ),
    )
    await Promise.all(
      deletableModels.map((m) =>
        api.put(`${API_VERSION}/models/disable`, {
          id_model: m.model?.id,
        }),
      ),
    )

    return {
      _id: 'project',
      ...response.data,
    }
  }

  if (modelData) {
    const {
      thumbnail,
      labels,
      lines,
      equipments,
      printers,
      nozzles,
      modelId,
      parentProjectId,
      ...model
    } = modelData
    const formData = new FormData()

    if (thumbnail) {
      formData.append(
        'thumbnail',
        await dataUrlToFile(thumbnail, 'thumbnail.png'),
      )
    }

    const files = (
      await Promise.all(
        model.files
          ?.filter((f) => !f.deletable)
          .map(async (f) => {
            if (f.file) {
              formData.append('file', f.file)
              return f
            }

            if (f.id && f.file_url && f.name) {
              const fileBlob = await dataUrlToFile(f.file_url, f.name, true)

              formData.append('file', fileBlob)

              return {
                ...f,
                file: fileBlob,
              }
            }

            return null!
          }) || [],
      )
    ).filter((f) => f)

    formData.append(
      'data',
      JSON.stringify({
        id_model: modelId,
        id_project: parentProjectId,

        model_files: parseFiles(files),
        ...model,
        files,
      }),
    )

    const parsedLabels = parseLabels({
      labels,
      lines,
      equipments,
      printers,
      nozzles,
    })

    const api = await getApi()
    const response = await api.post(`${API_VERSION}/revisionModels`, formData)

    const revisionModelId = response.data.id

    await Promise.all(
      parsedLabels.map(async (label) => {
        const response = await api.post(`${API_VERSION}/labels`, {
          id_revision_model: revisionModelId,
          type: 'model',
          name: label.name,
          category: label.category,
        })

        return response.data
      }),
    )

    return {
      _id: 'model',
      ...response.data,
    }
  }

  throw new Error('Required model or project')
}

export const createPart = async ({
  model: modelData,
  project: projectData,
}: CreatePartData): Promise<Part> => {
  if (projectData) {
    const {
      thumbnail: $thumbnail,
      files,
      models: $models,
      equipments,
      labels,
      lines,
      ...project
    } = projectData
    const models = $models.filter((m) => !m.deletable)
    const formData = new FormData()

    const thumbnail =
      $thumbnail || models.findLast((m) => m.thumbnail)?.thumbnail

    if (thumbnail) {
      formData.append(
        'thumbnail',
        await dataUrlToFile(thumbnail, 'thumbnail.png'),
      )
    }

    files?.forEach(({ file }) => {
      if (file) formData.append('project_file', file)
    })
    models.forEach(({ model }) => {
      model?.files.forEach(({ file }) => {
        if (file) formData.append('file', file)
      })
    })

    formData.append(
      'data',
      JSON.stringify({
        models: models.map(({ equipments, lines, labels, model, ...m }) => ({
          ...m,
          ...model,
          lines: parseLabels({ lines }),
          printers: parseLabels({ printers: model?.printers }),
          labels: parseLabels({ labels }),
          equipment: parseLabels({ equipments }),
          nozzle_size: parseLabels({ nozzles: model?.nozzles }),
          files: model?.files
            .filter((f) => f.file)
            .map((f) => ({
              size: f.file!.size,
              name: f.file!.name,
              file: f.file,
            })),

          ...(model && {
            _model_files: parseFiles(model.files),
          }),
        })),
        labels: parseLabels({
          equipments,
          labels,
          lines,
        }),
        project_files: parseFiles(files),
        ...project,
      }),
    )

    const api = await getApi()
    const response = await api.post(`${API_VERSION}/project`, formData)

    return {
      _id: 'project',
      ...response.data,
    }
  }

  if (modelData) {
    const {
      thumbnail,
      equipments,
      labels,
      lines,
      nozzles,
      printers,
      ...model
    } = modelData

    const formData = new FormData()

    if (thumbnail) {
      formData.append(
        'thumbnail',
        await dataUrlToFile(thumbnail, 'thumbnail.png'),
      )
    }

    formData.append(
      'data',
      JSON.stringify({
        _model_files: parseFiles(model.files),
        labels: parseLabels({
          equipments,
          labels,
          lines,
          nozzles,
          printers,
        }),
        model_files: model.files.map((f) => ({
          _id: f._id,
          name: f.name,
          file: f.file,
          size: f.file?.size,
          fileData: f.file,
        })),
        ...model,
      }),
    )

    model.files.forEach(({ file }) => {
      if (file) formData.append('file', file as Blob)
    })

    const response = await (
      await getApi()
    ).post(`${API_VERSION}/models/create`, formData)

    return {
      _id: 'model',
      ...response.data,
    }
  }

  throw new Error('Required model or project')
}
export const searchParts = async (
  params: SearchPartsParams,
): Promise<GetPartsResponse> => {
  const response = await (
    await getApi()
  ).get<GetPartsResponse>(`${API_VERSION}/parts/list`, {
    params: {
      mode: 'search',
      ...params,
    },
  })

  return response.data
}

export const getParts = async ({
  limit,
  mode,
  offset,
  ...searchPartsParams
}: GetPartsParams): Promise<GetPartsResponse> => {
  if (mode === 'search') {
    return searchParts(searchPartsParams)
  }

  const response = await (
    await getApi()
  ).get<GetPartsResponse>(`${API_VERSION}/parts/list`, {
    params: {
      mode: 'list',
      limit,
      with_thumb: true,
      offset,
    },
  })

  return response.data
}

export const getPartsCount = async (
  params: GetPartsParams,
): Promise<number> => {
  const response = await (
    await getApi()
  ).get(`${API_VERSION}/parts/list`, {
    params: {
      count: true,
      ...params,
    },
  })

  return response.data?.count?.parts
}

type FavoritePartData = {
  id_project?: number
  id_model?: number
}

export const favoritePart = async (data: FavoritePartData): Promise<void> => {
  await (await getApi()).post(`${API_VERSION}/favorites`, data)
}

type DisablePartData = {
  projectId?: number
  modelId?: number
}

export const disablePart = async (data: DisablePartData): Promise<void> => {
  if (data.projectId) {
    await (await getApi()).delete(`${API_VERSION}/project/${data.projectId}`)
  }
  if (data.modelId) {
    await (
      await getApi()
    ).put(`${API_VERSION}/models/disable`, {
      id_model: data.modelId,
    })
  }
}
type Budget = Partial<{
  filamentWeight: number
  filamentCostPerKg: number
  priceKWhPerMinute: number
  printerConsumptionWatts: number
  averagePrintFailuresPercentage: number

  desiredTimeInMonths: number
  machinePrice: number
  workingHoursPerDay: number
  daysPerMonth: number

  desiredProfitPercentage: number
}>

export type BudgetFilesData = (Budget & { fileId: number })[]
type CalcBudgetData = {
  modelId: number
  budgetFilesData?: BudgetFilesData
  omitFiles?: number[]
}

export type CalcBudgetResponse = {
  filamentName: string
  budget: number
  modelId: number
  files: (CostCalculatorValuesPerMaterial & {
    fileId: number
    partsQttd: number
    omitted: boolean
    fileName: string
  })[]
}[]

export const calcBudget = async (
  data: CalcBudgetData,
): Promise<CalcBudgetResponse> => {
  const response = await (
    await getApi()
  ).post<CalcBudgetResponse>(`${API_VERSION}/parts/budget`, data)

  return response.data
}
type SendMailBudgetData = {
  modelId: number
  budgetFilesData?: BudgetFilesData
  omitFiles?: number[]
  recipientEmail?: string
}
export const sendMailBudget = async (
  data: SendMailBudgetData,
): Promise<void> => {
  await (await getApi()).post(`${API_VERSION}/parts/budget/send-mail`, data)
}
