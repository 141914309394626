import { API_VERSION } from '../../../config'
import { getApi } from '../../api'
import { Task } from '../tasks'
import { User } from '../users'

export type Printer = {
  id: number
  name: string | null
  jobName: string | null
  bedTemperature: number | null
  toolTemperature: number | null
  progress: number | null
  estimatedPrintTime: string | null
  printTimeLeftInSeconds: number | null
  printTimeLeft: string | null
  description: string | null
  location: string | null
  ip: string | null
  userId: number | null
  online: boolean | null
  createdAt: Date
  updatedAt: Date
  status: string
  tasks: Task[]
  paused: boolean
  cancelling: boolean
  error: boolean
  finishing: boolean
  pausing: boolean
  printing: boolean
  ready: boolean
  baseUrl?: string | null
  localUrl?: string | null
  apiKey?: string | null
  initialConnection: Date | null
  octoPrintVersion: string | null
  enable: boolean | null
  model?: string | null
  user?: User
}

export type PrinterModel = {
  id: number
  modelName: string
  bedsizeX: number
  bedsizeY: number
  enable: boolean
  updatedAt: Date
  createdAt: Date
  priceperkwh: number
  consume: number
  failureavg: number
  machinePrice: number
  daysPerMonth: number
  workingHoursPerDay: number
  desiredTimeMonth: number
}
type GetPrintersParams = Partial<{
  offset: number
  limit: number
  unity?: string
  name?: string
  model?: string
  description?: string
  baseUrl?: string
  localUrl?: string
  apiKey?: string
  mode: 'search' | 'list' | 'count'
}>

export type GetPrintersResponse = {
  printers: Printer[]
  printers_total_count: number
  printers_count: number
}

export type GetPrinterActionsResponse = {
  id: number
  fileName: string | null
  fileId: string | null
  printerName: string
  userName: string
  action: string
  createdAt: Date
}[]

export type GetPrinterErrorsResponse = {
  id: number
  error: string | null
  createdAt: Date
  printer: {
    name: string
  }
}[]

type SearchPrintersParams = {
  unity?: string
  name?: string
  model?: string
  description?: string
  baseUrl?: string
  localUrl?: string
  apiKey?: string
}

type CreatePrinterData = {
  name: string
  description?: string | null
  baseUrl?: string | null
  localUrl?: string | null
  model: string
  apiKey?: string | null
}

type EditPrinterData = Partial<{
  name: string
  description: string
  baseUrl: string
  localUrl: string
  model: string
  apiKey: string
}> & {
  printerId: number
}

export const fetchPrinter = async (printerId: number): Promise<Printer> => {
  const response = await (
    await getApi()
  ).get<Printer>(`${API_VERSION}/printers/fetch`, {
    params: {
      id_printer: printerId,
    },
  })

  return response.data
}

export const getPrinterActions =
  async (): Promise<GetPrinterActionsResponse> => {
    const response = await (
      await getApi(true)
    ).get(`${API_VERSION}/admins/printer-actions/list`)

    return (response.data?.printerActions || []) as GetPrinterActionsResponse
  }
export const getPrinterErrors = async (): Promise<GetPrinterErrorsResponse> => {
  const response = await (
    await getApi(true)
  ).get(`${API_VERSION}/admins/printer-errors/list`)

  return (response.data?.printerErrors || []) as GetPrinterErrorsResponse
}

export const disablePrinter = async (printerId: number): Promise<void> => {
  await (
    await getApi()
  ).put(`${API_VERSION}/printers/change-enable`, {
    id_printer: printerId,
    state: 'disable',
  })
}
export const editPrinter = async ({
  printerId,
  ...data
}: EditPrinterData): Promise<void> => {
  await (
    await getApi()
  ).put(`${API_VERSION}/printers/update`, {
    ...data,
    id_printer: printerId,
  })
}

export const createPrinter = async (
  data: CreatePrinterData,
): Promise<Printer> => {
  const response = await (
    await getApi()
  ).post<Printer>(`${API_VERSION}/printers/create`, data)

  return response.data
}
export const searchPrinters = async (
  params: SearchPrintersParams,
): Promise<GetPrintersResponse> => {
  const response = await (
    await getApi()
  ).get<GetPrintersResponse>(`${API_VERSION}/printers/list`, {
    params: {
      mode: 'search',
      ...params,
    },
  })

  return response.data
}

export const getPrinters = async ({
  limit,
  mode,
  offset,
  ...searchPrintersParams
}: GetPrintersParams): Promise<GetPrintersResponse> => {
  if (mode === 'search') {
    return searchPrinters(searchPrintersParams)
  }

  const response = await (
    await getApi()
  ).get<GetPrintersResponse>(`${API_VERSION}/printers/list`, {
    params: {
      mode: 'list',
      limit,
      offset,
    },
  })

  return response.data
}

export const getUnityPrintersWithTasks = async (): Promise<Printer[]> => {
  const response = await (
    await getApi()
  ).get<Printer[]>(`${API_VERSION}/printers/list-unity-printers-with-tasks`)

  return response.data
}

export const getPrintersCount = async (
  params: GetPrintersParams,
): Promise<GetPrintersResponse> => {
  const response = await (
    await getApi()
  ).get<GetPrintersResponse>(`${API_VERSION}/printers/list`, {
    params: {
      mode: 'count',
      ...params,
    },
  })

  return response.data
}

type PrinterModelCommons = 'id' | 'createdAt' | 'updatedAt'

type GetPrinterModelsParams = {
  revisionModelId?: number
}
export const getPrinterModels = async (
  params?: GetPrinterModelsParams,
): Promise<PrinterModel[]> => {
  const response = await (
    await getApi(true)
  ).get<PrinterModel[]>(`${API_VERSION}/printers/models/list`, {
    params,
  })

  return response.data
}

type UpdatePrinterModelData = Partial<
  Omit<PrinterModel, PrinterModelCommons>
> & {
  printerModelId: number
}

export const updatePrinterModel = async (
  data: UpdatePrinterModelData,
): Promise<PrinterModel> => {
  const response = await (
    await getApi()
  ).put<PrinterModel>(`${API_VERSION}/admins/printers/models/update`, data)

  return response.data
}
export const deletePrinterModel = async (
  printerModelId: number,
): Promise<void> => {
  await (
    await getApi()
  ).delete<PrinterModel>(`${API_VERSION}/admins/printers/models/delete`, {
    params: {
      printerModelId,
    },
  })
}

type CreatePrinterModelData = Omit<PrinterModel, PrinterModelCommons | 'enable'>

export const createPrinterModel = async (
  data: CreatePrinterModelData,
): Promise<PrinterModel> => {
  const response = await (
    await getApi()
  ).post<PrinterModel>(`${API_VERSION}/admins/printers/models/create`, data)

  return response.data
}
