import { API_VERSION } from '../../../config'
import { getApi } from '../../api'

export const stopJob = async (printerId: number): Promise<void> => {
  await (
    await getApi()
  ).post(`${API_VERSION}/job/cancel`, {
    printerId,
  })
}
export const newJob = async (formData: FormData): Promise<void> => {
  await (await getApi()).post(`${API_VERSION}/job/upload`, formData)
}

export const startJob = async (printerId: number): Promise<void> => {
  await (
    await getApi()
  ).post(`${API_VERSION}/job/start`, {
    printerId,
  })
}
