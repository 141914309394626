import { API_VERSION } from '../../../config'
import { getApi } from '../../api'

export type Notification = {
  id: number
  content: string
  read: boolean
  type: string
  redirect: string
}

type GetNotificationsParams = {
  limit: number
  offset: number
}

export type ChangeNotificationSettingsParams = {
  email_notification?: boolean
  unity_notification?: boolean
  system_notification?: boolean
}

export const getNotifications = async (
  params: GetNotificationsParams,
): Promise<Notification[]> => {
  const response = await (
    await getApi()
  ).get<Notification[]>(`${API_VERSION}/notifications`, {
    params,
  })

  return response.data
}

export const markManyNotificationsAsRead = async (): Promise<void> => {
  await (
    await getApi()
  ).patch<Notification[]>(`${API_VERSION}/notifications/markAsRead`)
}

type MarkOneNotificationAsReadParams = {
  id_notification: number
}

export const markOneNotificationAsRead = async (
  params: MarkOneNotificationAsReadParams,
): Promise<void> => {
  await (
    await getApi()
  ).patch(`${API_VERSION}/notification/markAsRead`, {
    ...params,
  })
}

export const deleteNotification = async (
  notificationId: number,
): Promise<void> => {
  await (await getApi()).delete(`${API_VERSION}/notification/${notificationId}`)
}

export const changeNotificationSettings = async (
  params: ChangeNotificationSettingsParams,
): Promise<void> => {
  await (
    await getApi()
  ).patch(`${API_VERSION}/user/notificationSettings`, {
    ...params,
  })
}
