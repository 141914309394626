import { API_VERSION } from '../../../config'
import { getApi } from '../../api'
import { PrinterModel, getPrinterModels } from '../printers'

export type Label = {
  id: number
  name: string
  description: string | null
  category: string
  enable: boolean
  createdAt: Date
  updatedAt: Date
}

type GetLabelsParams = {
  category?: string
  id_project?: number
  id_revision_model?: number
}

type GetLabelLangsParams = Partial<{
  offset: number
  limit: number
  ssr?: boolean
  name: string
  mode: 'search' | 'list' | 'count'
}>

export type TLabel = {
  id: number
  en: string
  pt: string
  es: string
  original: string
}

export type GetLabelLangsResponse = {
  label_langs: TLabel[]
  label_langs_total_count: number
  label_langs_count: number
}

type SearchLabelLangsParams = Partial<{
  name: string
}>

export type EditLabelLangsData = {
  labelId: number
  pt?: string
  en?: string
  es?: string
  original: string
}

function toLabels(data: PrinterModel[]): Label[] {
  return data.map((printerModel) => ({
    id: printerModel.id,
    name: printerModel.modelName,
    description: null,
    category: 'printer',
    enable: true,
    createdAt: printerModel.createdAt,
    updatedAt: printerModel.updatedAt,
  }))
}

export const getLabels = async (params: GetLabelsParams): Promise<Label[]> => {
  if (params.category === 'printer') {
    return toLabels(
      await getPrinterModels({
        revisionModelId: params.id_revision_model,
      }),
    )
  }

  const response = await (
    await getApi()
  ).get<Label[]>(`${API_VERSION}/labels/list`, {
    params,
  })

  return response.data
}

export const deleteLabelLangs = async (labelId: number): Promise<void> => {
  await (await getApi()).delete(`${API_VERSION}/label/${labelId}`)
}

export const disableLabel = async (labelId: number): Promise<void> => {
  await (
    await getApi()
  ).put(`${API_VERSION}/label`, {
    labelId,
    enable: false,
  })
}

export const editLabelLangs = async (
  data: EditLabelLangsData,
): Promise<void> => {
  await (await getApi()).put(`${API_VERSION}/labels/edit-lang`, data)
}

export const searchLabelLangs = async (
  params: SearchLabelLangsParams,
): Promise<GetLabelLangsResponse> => {
  const response = await (
    await getApi()
  ).get<GetLabelLangsResponse>(`${API_VERSION}/labels/list-langs`, {
    params: {
      mode: 'search',
      ...params,
    },
  })

  return response.data
}

export const getLabelLangs = async ({
  limit,
  mode,
  ssr,
  offset,
  ...searchLabelLangsParams
}: GetLabelLangsParams): Promise<GetLabelLangsResponse> => {
  if (mode === 'search') {
    return searchLabelLangs(searchLabelLangsParams)
  }

  const response = await (
    await getApi(ssr)
  ).get<GetLabelLangsResponse>(`${API_VERSION}/labels/list-langs`, {
    params: {
      mode: 'list',
      limit,
      offset,
    },
  })

  return response.data
}

export const getLabelLangsCount = async (
  params: GetLabelLangsParams,
): Promise<GetLabelLangsResponse> => {
  const response = await (
    await getApi()
  ).get<GetLabelLangsResponse>(`${API_VERSION}/labels/list-langs`, {
    params: {
      mode: 'count',
      ...params,
    },
  })

  return response.data
}

type DisassociateLabelsParams = {
  projectId: number
  labels: number[]
}

export const disassociateLabels = async ({
  labels,
  projectId,
}: DisassociateLabelsParams): Promise<void> => {
  const response = await (
    await getApi()
  ).delete(
    `${API_VERSION}/labels/disassociate-project-labels/?labels=${JSON.stringify(
      labels,
    )}&id_project=${projectId}`,
  )

  return response.data
}
