import { admins as tasks } from './resources/tasks'

export * from './resources/downloads'
export * from './resources/labels'
export * from './resources/maintenance-logs'
export * from './resources/notifications'
export * from './resources/stats'
export * from './resources/unities'
export * from './resources/users'
export * from './resources/printers'
export * from './resources/jobs'
export * from './resources/tasks'
export * from './resources/files'
export * from './resources/parts'

export const admins = {
  tasks,
}
