import { API_VERSION } from '../../../config'
import { getApi } from '../../api'

export type Task = {
  id: number
  title: string
  description: string | null
  link: string
  done: boolean
  periodicity: string
  endAt?: Date
  createdAt: string
  updatedAt: string
}

type CreateTaskData = {
  title: string
  description?: string
  periodicity?: string
  link: string
}

type EditTaskData = Partial<{
  title: string
  description: string
  link: string
}> & {
  taskId: number
}

type DoneTaskData = {
  taskId: number
  printerId: number
  done: true
}

const deleteTask = async (taskId: number): Promise<void> => {
  await (
    await getApi()
  ).delete(`${API_VERSION}/admins/tasks/delete`, {
    params: {
      taskId,
    },
  })
}

const editTask = async ({ taskId, ...data }: EditTaskData): Promise<void> => {
  await (
    await getApi()
  ).put(`${API_VERSION}/admins/tasks/update`, {
    ...data,
    taskId,
  })
}

export const doneTask = async ({
  taskId,
  printerId,
  done,
}: DoneTaskData): Promise<void> => {
  await (
    await getApi()
  ).put(`${API_VERSION}/tasks/update`, {
    done,
    printerId,
    taskId,
  })
}

const createTask = async (data: CreateTaskData): Promise<Task> => {
  const response = await (
    await getApi()
  ).post<Task>(`${API_VERSION}/admins/tasks/create`, data)

  return response.data
}

export const getTasks = async (): Promise<Task[]> => {
  const response = await (
    await getApi()
  ).get<Task[]>(`${API_VERSION}/tasks/list`)

  return response.data
}

export const getAllTasks = async (): Promise<Task[]> => {
  const response = await (
    await getApi()
  ).get<Task[]>(`${API_VERSION}/tasks/list-all`)

  return response.data
}

export const admins = {
  deleteTask,
  editTask,
  createTask,
}
