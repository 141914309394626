import { API_VERSION } from '../../../config'
import { getApi } from '../../api'

export type Download = {
  id: number
  username: string | null
  id_user: number | null
  ip: string | null
  id_project: number | null
  id_model: number | null
  id_file: number | null
  project: string | null
  model: string | null
  file: string | null
  createdAt: Date
  updatedAt: Date
}

type SearchDownloadsParams = Partial<{
  name: string
  username: string
  ip: string
  project: string
  model: string
  file: string
}>

export type GetDownloadsResponse = {
  downloads: Download[]
  downloads_total_count: number
  downloads_count: number
}

export type GetDownloadsParams = {
  offset?: number
  limit?: number
  ssr?: boolean
  mode?: 'search' | 'list' | 'count'
} & SearchDownloadsParams

export const getDownloads = async ({
  limit,
  mode,
  ssr,
  offset,
  ...searchDownloadsParams
}: GetDownloadsParams): Promise<GetDownloadsResponse> => {
  if (mode === 'search') {
    return searchDownloads(searchDownloadsParams)
  }

  const response = await (
    await getApi(ssr)
  ).get<GetDownloadsResponse>(`${API_VERSION}/downloads`, {
    params: {
      mode: 'list',
      limit,
      offset,
    },
  })

  return response.data
}

export const getDownloadsCount = async (
  params: GetDownloadsParams,
): Promise<GetDownloadsResponse> => {
  const response = await (
    await getApi()
  ).get<GetDownloadsResponse>(`${API_VERSION}/downloads`, {
    params: {
      mode: 'count',
      ...params,
    },
  })

  return response.data
}

export const searchDownloads = async (
  params: SearchDownloadsParams,
): Promise<GetDownloadsResponse> => {
  const response = await (
    await getApi()
  ).get<GetDownloadsResponse>(`${API_VERSION}/downloads`, {
    params: {
      mode: 'search',
      ...params,
    },
  })

  return response.data
}
