import { API_VERSION } from '@/config'
import { getApi } from '@/services/api'
import { User } from '../users'

type GetCommentariesParams = {
  id_project?: number
  id_model?: number
}

export type Commentary = {
  id: number
  translatedCommentary?: string
  originalCommentary?: string
  showTranslate?: boolean
  user: User | null
  commentary: string
  createdAt: string
}

export const getCommentaries = async (
  params: GetCommentariesParams,
): Promise<Commentary[]> => {
  const response = await (
    await getApi()
  ).get(`${API_VERSION}/commentary/part`, {
    params,
  })

  return response.data
}

type CreateCommentaryData = {
  mentions: number[]
  commentary: string
  modelId?: number
  projectId?: number
}

type MentionUserData = {
  mentions: number[]
  modelId?: number
  projectId?: number
}

const mentionUser = async ({
  mentions,
  modelId,
  projectId,
}: MentionUserData): Promise<Commentary[]> => {
  const response = await (
    await getApi()
  ).post(`${API_VERSION}/user/mention`, {
    ...(modelId
      ? {
          id_model: modelId,
        }
      : {
          id_project: projectId,
        }),
    mentioned_users: mentions,
  })

  return response.data
}

export const createCommentary = async ({
  mentions,
  modelId,
  projectId,
  ...data
}: CreateCommentaryData): Promise<Commentary> => {
  const response = await (
    await getApi()
  ).post(`${API_VERSION}/commentary`, {
    ...(modelId
      ? {
          id_model: modelId,
        }
      : {
          id_project: projectId,
        }),
    ...data,
  })

  if (mentions && mentions.length > 0) {
    await mentionUser({
      modelId,
      projectId,
      mentions,
    })
  }

  return response.data
}

type EditCommentaryData = {
  mentions?: number[]
  commentary: string
  commentaryId: number
  modelId?: number
  projectId?: number
}

export const editCommentary = async ({
  mentions,
  commentaryId,
  modelId,
  projectId,
  ...data
}: EditCommentaryData): Promise<Commentary> => {
  const response = await (
    await getApi()
  ).put(`${API_VERSION}/commentary`, {
    id_commentary: commentaryId,
    ...data,
  })

  if (mentions && mentions.length > 0) {
    await mentionUser({
      modelId,
      projectId,
      mentions,
    })
  }

  return response.data
}

export const deleteCommentary = async (commentaryId: number): Promise<void> => {
  await (await getApi()).delete(`${API_VERSION}/commentary/${commentaryId}`)
}
