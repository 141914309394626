import { FilterData } from '@/components/part-item-card/filament'
import { API_VERSION } from '@/config'
import { getApi } from '@/services/api'

export type File = {
  id?: number
  _id?: string
  id_model?: number | null
  id_revision_model?: number | null
  id_revision?: number | null
  id_project?: number | null
  currentJob?: boolean
  printerId?: number | null
  name?: string | null
  size?: string | null
  partsQttd?: number | null
  file_url?: string | null
  file_type?: string | null
  enable?: boolean
  createdAt?: Date
  updatedAt?: Date
  nft?: {
    nft_contract_address: string
    id_token: number
    tx_hash: string
  } | null
  totalFilamentUsed?: string | null
  normalModeEstimatedPrintingTime?: string | null
  silentModeEstimatedPrintingTime?: string | null
}
export const downloadFile = async (fileId: number): Promise<Blob> => {
  const response = await (
    await getApi()
  ).get(`${API_VERSION}/files/download`, {
    responseType: 'blob',
    params: {
      id_file: fileId,
    },
  })

  return response.data
}
export const getFileBlob = async (fileUrl: string): Promise<Blob> => {
  const response = await (
    await getApi()
  ).get(`${fileUrl}`, {
    responseType: 'blob',
  })

  return response.data
}

type GetFilesParams = {
  projectId?: number
  revisionProjectId?: number
}

export const getFiles = async ({
  projectId,
  revisionProjectId,
}: GetFilesParams): Promise<File[]> => {
  const response = await (
    await getApi()
  ).get(`${API_VERSION}/files`, {
    params: {
      id_project: projectId,
      id_revision: revisionProjectId,
    },
  })

  return response.data
}

type FetchFileParams = {
  fileId: number
} & FilterData
export type CostCalculatorValuesPerMaterial = {
  id: number
  filamentName: string
  pieceValuesSlicer: {
    lengthFilamentUsed: number
    printingTimeInMinute: number
    printingTimeInHour: number

    filamentDiameter: number
    estimatedDiameter: number

    filamentDensity: number
    estimatedDensity: number

    weight: number
    filamentWeight: number
    estimatedWeight: number

    areaDiameterFilament: number
  }
  materialAndPrinterSpecifications: {
    filamentCostPerKg: number
    priceKWhPerMinute: number
    printerConsumptionWatts: number
    averagePrintFailuresPercentage: number
    filamentCostPerGrams: number
    printerConsumptionKWh: number
  }
  printingRelatedCosts: {
    filamentCost: number
    energyCost: number
    maintenanceCost: number
    failuresCost: number
    finishingCost: number
    totalProductionCost: number
  }
  roi: {
    desiredTimeInMonths: number
    printerPrice: number
    workingHoursPerDay: number
    daysPerMonth: number
    hourCost: number
    totalReturnCost: number
  }
  costsSum: {
    desiredProfitPercentage: number
    totalProductionCost: number
    finalSaleValue: number
    finalSaleValueParts: number
  }
}
export type FetchFileResponse = File &
  Partial<{
    filamentDiameter: string | null
    filamentDensity: string | null
    totalFilamentUsed: string | null
    normalModeEstimatedPrintingTime: string | null
    silentModeEstimatedPrintingTime: string | null
    costCalculatorValuesPerMaterial: CostCalculatorValuesPerMaterial[]
  }>

export const fetchFile = async (
  params: FetchFileParams,
): Promise<FetchFileResponse> => {
  const response = await (
    await getApi()
  ).get(`${API_VERSION}/files/fetch`, {
    params,
  })

  return response.data
}
export const downloadPartCsv = async (data: {
  projectId?: number
  modelId?: number
}): Promise<Blob> => {
  const response = await (
    await getApi()
  ).post(`${API_VERSION}/admins/downloads/csv/project-model`, data, {
    responseType: 'blob',
  })

  return response.data
}

export const downloadPartsCsv = async (
  type: 'projects' | 'models',
): Promise<Blob> => {
  const response = await (
    await getApi()
  ).post(
    `${API_VERSION}/admins/downloads/csv/projects/list`,
    {
      type,
    },
    {
      responseType: 'blob',
    },
  )

  return response.data
}
