import { parseISO } from 'date-fns'
import format from 'date-fns/format'
import { API_VERSION } from '../../../config'
import { getApi } from '../../api'

export type MaintenanceLog = {
  id: number
  username: string | null
  unity: string | null
  printerName: string | null
  taskName: string | null
  periodicity: string | null
  wouldExpireInDays: string
  taskCompletionDate: string
}

type GetMaintenanceLogsParams = Partial<{
  offset: number
  limit: number
  username?: string
  unity?: string
  printerName?: string
  taskName?: string
  periodicity?: string
  wouldExpireInDays?: number
  mode: 'search' | 'list' | 'count'
}>

export type GetMaintenanceLogsResponse = {
  maintenance_logs: MaintenanceLog[]
  maintenance_logs_total_count: number
  maintenance_logs_count: number
}

type SearchMaintenanceLogsParams = {
  username?: string
  unity?: string
  printerName?: string
  taskName?: string
  periodicity?: string
  wouldExpireInDays?: number
}

const parseResponse = (response: GetMaintenanceLogsResponse) => {
  return {
    ...response,
    maintenance_logs: response.maintenance_logs.map((maintenanceLog) => ({
      ...maintenanceLog,
      taskCompletionDate: format(
        parseISO(maintenanceLog.taskCompletionDate),
        'dd/MM/yyyy HH:mm:ss',
      ),
    })),
  }
}

export const searchMaintenanceLogs = async (
  params: SearchMaintenanceLogsParams,
): Promise<GetMaintenanceLogsResponse> => {
  const response = await (
    await getApi()
  ).get<GetMaintenanceLogsResponse>(`${API_VERSION}/maintenance-logs/list`, {
    params: {
      mode: 'search',
      ...params,
    },
  })

  return parseResponse(response.data)
}

export const getMaintenanceLogs = async (
  params?: GetMaintenanceLogsParams,
): Promise<GetMaintenanceLogsResponse> => {
  const { limit, mode, offset, ...searchMaintenanceLogsParams } = params || {}
  if (mode === 'search') {
    return searchMaintenanceLogs(searchMaintenanceLogsParams)
  }

  const response = await (
    await getApi(true)
  ).get<GetMaintenanceLogsResponse>(`${API_VERSION}/maintenance-logs/list`, {
    params: {
      mode: 'list',
      limit,
      offset,
    },
  })

  return parseResponse(response.data)
}

export const getMaintenanceLogsCount = async (
  params: GetMaintenanceLogsParams,
): Promise<GetMaintenanceLogsResponse> => {
  const response = await (
    await getApi()
  ).get<GetMaintenanceLogsResponse>(`${API_VERSION}/maintenance-logs/list`, {
    params: {
      mode: 'count',
      ...params,
    },
  })

  return parseResponse(response.data)
}
