import { API_VERSION } from '../../../config'
import { getApi } from '../../api'

export type Stats = {
  totalFilamentPerMonth: {
    filamentLength: number
    month: string
  }[]
  numberOfImpressions: {
    total: number
    month: string
  }[]
  numberOfHits: {
    total: number
    hits: number
  }
  qtdHoursPrinting: {
    elapsedPrintTime: number
    month: string
  }[]
  qtdFilament: {
    filamentLength: number
    month: string
  }[]
}

type GetAllStatsParams = {
  unity: string | null
}

export const getAllStats = async (
  params: GetAllStatsParams,
): Promise<Stats | null> => {
  const response = await (
    await getApi()
  ).get<Stats>(`${API_VERSION}/stats/all`, {
    params,
  })

  return response.data
}
